// --- deleteData
// send DELETE method to server
// server system will supply responses,
// try/catch only revolves around browser-side issues

import Axios from "axios"

export default async function deleteData ({ route, id, data }) {
    try {
        if ( !route ) return "Route must be sent as an object!"
        const requestRoute = id ? route.replace( ":id", id ) : route

        const response = data
            // eslint-disable-next-line dot-notation
            ? await Axios.delete( requestRoute, data )
            // eslint-disable-next-line dot-notation
            : await Axios.delete( requestRoute )

        return response.data
    }
    catch ( error ) {
        const { response: { data, headers: { "content-type": contentType } } } = error

        return {
            contentType,
            error: data
        }
    }
}