// --- fetchData
import Axios from "axios"

import { routes } from "services/constants.js"

export default async function fetchData ({ route }, params = {}) {
    if ( !route ) return "Route must be sent as an object!"

    const selectedRoute = routes[route] || route

    try {
        const { data } = await Axios.get( selectedRoute, params )

        return data
    }
    catch ( error ) {
        if ( error.response && error.response.data ) {
            return {
                error: error.response.data,
                status: error.response.status
            }
        }

        if ( error.message ) {
            return { error: error.message }
        }

        return { error }
    }
}
