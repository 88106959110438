// --- updateDataNoCatch
// we should rethink how this / updateData play w each other in the future - CP

import Axios from "axios"

import { routes } from "services/constants.js"

export default async function updateDataNoCatch ({ route, data: payload }) {
    if ( !route ) return "Route must be sent as an object!"

    const selectedRoute = routes[route] || route

    const response = await Axios.post( selectedRoute, payload )

    return response.data
}