// --- updateData

import Axios from "axios"

import { routes } from "services/constants.js"

export default async function updateData ({ route, data: payload }) {
    if ( !route && route !== "" ) return "Route must be sent as an object!"

    const selectedRoute = routes[route] || route

    try {
        const response = await Axios.post( selectedRoute, payload )

        return response.data
    }
    catch ( error ) {
        const { response: { data, headers: { "content-type": contentType } } } = error

        return {
            contentType,
            error: data.errors || data,
            message: data.message,
        }
    }
}
