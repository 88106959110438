// --- useApi
import deleteData from "./lib/deleteData.js"
import fetchData from "./lib/fetchData.js"
import fetchHeaders from "./lib/fetchHeaders.js"
import fetchWithCancel from "./lib/fetchWithCancel.js"
import generateThumbnail from "./lib/generateThumbnail.js"
import insertGet from "./lib/insertGet.js"
import putData from "./lib/putData.js"
import updateData from "./lib/updateData.js"
import updateDataNoCatch from "./lib/updateDataNoCatch.js"

export default {
    delete: deleteData,
    fetch: fetchData,
    fetchHeaders,
    fetchWithCancel,
    generateThumbnail,
    insert_get: insertGet,
    put: putData,
    update: updateData,
    updateNoCatch: updateDataNoCatch,
}
