// --- contants for use in services
// TODO: move this into a /data directory towards end of UI unification

import { parseObject } from "utils/parsers"

export const confirmationMessage = "Warning: Leaving this page will result in any unsaved data being lost. Are you sure you wish to continue?"

export const CDN = {
    media: "https://cdn.buttercms.com/",
    processing: "https://cdn.buttercms.com/"
}

export const routes = {
    createLibraryComponent: "/content-types/r/components/",
    getLibraryComponent: "/content-types/r/components/:id/",
    getLibraryComponents: "/content-types/r/components/",
    media: "/media/r/get_media/",
    mediaDashboard: "/media/r/dashboard/",
    mediaTags: "/media/tags/:id",
    mediaTagsList: "/media/r/tags/",
    references: "/references/r/referencepicker",
    removeLibraryComponent: "/content-types/r/components/:id/",
    saveMedia: "/media/r/save_media",
    updateLibraryComponent: "/content-types/r/components/:id/"
}
