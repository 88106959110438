// --- putData
// put is used for updating
// but since we already have a function with name updateData for post method
// we name this function differently

// TODO: determine where updateData (POST) and putData (PUT)
//       are used and if we can combine them

import Axios from "axios"

import { routes } from "services/constants.js"

export default async function putData ({ route, data: payload }) {
    if ( !route && route !== "" ) return "Route must be sent as an object!"

    const selectedRoute = routes[route] || route

    try {
        const response = await Axios.put( selectedRoute, payload )

        return response.data
    }
    catch ( error ) {
        const { response: { data, headers: { "content-type": contentType } } } = error

        return {
            contentType,
            error: data.errors || data
        }
    }
}