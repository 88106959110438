// --- useApi

// Axios declaration
// set up CSRF for use with Django.Python server
// vue-resource is "depreciated;
// seeL https://medium.com/the-vue-point/retiring-vue-resource-871a82880af4
import Axios from "axios"
Axios.defaults.xsrfCookieName = "csrftoken"
Axios.defaults.xsrfHeaderName = "X-CSRFToken"

export { CDN, routes } from "services/constants.js"

export { default } from "./useApi.js"