// --- insertGet
// this is a weird one, it's a get request that inserts data into the database

import Axios from "axios"

import { routes } from "services/constants.js"

export default async function insertGet ({ route, data: params }) {
    if ( !route ) return "Route must be sent as an object!"

    const selectedRoute = routes[route] || route

    try {
        const { data } = await Axios.get(
            selectedRoute,
            { params }
        )

        return data
    }
    catch ( error ) {
        const { response: { data } } = error

        return {
            error: data
        }
    }
}