// ---  generateThumbnail
// send request to Filestack to produce a smaller image for our purposes
// this should be cached on one of the CDNs once its been created

import { CDN } from "services/constants.js"

export default function generateThumbnail ({ src, width }) {
    const media = src.replace(
        CDN.media,
        `${ CDN.processing }resize=width:${ width }/`
    )

    return media
}