// --- fetchWithCancel
// wrapper for fetch function to cancel all previous pending requests
// useful when user may make the same requests multiple times
// without waiting for response

import Axios from "axios"

import fetchData from "./fetchData.js"

const cancelTokens = {}

export default function fetchWithCancel ( route ) {
    // Check if there are any previous pending requests
    if ( typeof cancelTokens[route] !== "undefined" ) {
        cancelTokens[route].cancel( "Operation canceled due to new request." )
    }

    // Save the cancel token for the current request
    cancelTokens[route] = Axios.CancelToken.source()

    const cancelTokenParam = { cancelToken: cancelTokens[route].token }

    return fetchData({ route }, cancelTokenParam )
}